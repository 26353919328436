import gsap from "gsap";
import { loading_start, loading_end } from "./config/animation";
import imagesLoaded from "imagesloaded";

export default function loading(_callback) {

    let elMain = document.querySelector("#main");

    let elLoading = document.querySelector("#loading");
    let elLoadingtext = document.querySelector(".loading-text");

    let loadedImageCount = 0;
    let imageCount = 0;

    // Show progress
    function updateProgress(value) {
        const diplay = value / imageCount;
        try {
            elLoadingtext.firstChild.data = `${Math.round(diplay * 100)}%`;
        } catch (error) {}
    }

    const imgLoad = imagesLoaded(elMain, {
        background: true
    });

    imageCount = imgLoad.images.length;

    imgLoad.on("progress", () => {
        loadedImageCount += 1;
        updateProgress(loadedImageCount);
    });


    // Images done loaded
    imgLoad.on("always", () => {

        gsap.to(elLoading, loading_end);

        _callback();
    });

}

export function Loading_enter(_callback) {

    let elLoading = document.querySelector("#loading");
    const tl_loadcomplete_enter = gsap.timeline({
        delay: 0.1,
        onComplete: _callback()
    });

    tl_loadcomplete_enter.to(elLoading, loading_end);
}

export function pageTransition() {
    const tl_transition = gsap.timeline();

    tl_transition.to("#loading", loading_start, "-=0.4");
}