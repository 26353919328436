import gsap from "gsap";
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

const global_duration = 1.4;

const configAnimation = {
    global_duration: global_duration,
    global_stagger: 0.15,
    global_trigger: "top bottom-=20%",
    global_offset: `-=${global_duration - 0.4}`,
    global_offset_duration: `-=${global_duration}`,
    global_ease: "power4.out",
    global_scroller: ".smooth-scroll",
}

// From
export const anim_revealBottom = {
    yPercent: 100,
    stagger: 0.05,
    ease: "power4.out",
    force3D: true
}

// From
export const anim_fadeBottom = {
    y: 40,
    opacity: 0,
    ease: "expo.out",
    stagger: configAnimation.global_stagger - 0.05
}

export const anim_fadeTop = {
    y: -40,
    opacity: 0,
    stagger: configAnimation.global_stagger - 0.05
}

// From
export const anim_scaleUp = {
    scale: 0,
}

// From
export const anim_perchar = {
    yPercent: 100,
    stagger: 0.06,
    duration: configAnimation.global_duration - 0.4
}

// To
export const loading_end = {
    opacity: 0,
    duration: 0.7,
    display: "none",
    ease: "none"
}

export const loading_start = {
    opacity: 1,
    duration: 0.7,
    ease: "none",
    display: "flex",
}

export const text_enter = {
    y: 0,
    opacity: 1,
}

export const text_exit = {
    y: -40,
    opacity: 0,
}

export const cursor_active = {
    width: "90px",
    height: "90px",
    background: "#FFFFFF",
    duration: configAnimation.global_duration - 0.8,
}

export const cursor_inactive = {
    width: "45px",
    height: "45px",
    background: "transparent",
    duration: configAnimation.global_duration - 0.8,
}

// Utility helper
export function Utility() {

    // Split per line
    try {
        gsap.utils.toArray(".split-line").forEach((el, i) => {
            new SplitText(el, {
                type: "lines",
                linesClass: "line",
                position: "relative"
            });
        });
    } catch (error) {}


    // Split per line (adding masking)
    try {
        gsap.utils.toArray(".reveal-text").forEach((el, i) => {
            const elLines = new SplitText(el, {
                type: "lines",
                linesClass: "line",
                position: "relative"
            });

            gsap.utils.toArray(elLines.lines).forEach((el, i) => {
                let wrapper = document.createElement('div');
                wrapper.classList.add("masking-text");
                el.parentNode.insertBefore(wrapper, el);
                wrapper.appendChild(el);
            });
        });
    } catch (error) {}

    // Split per word, char
    try {
        gsap.utils.toArray(".split-text").forEach((el, i) => {
            new SplitText(el, {
                type: "words, chars",
                charsClass: "char",
                wordsClass: "word",
                position: "relative"
            });
        });
    } catch (error) {}

}

export default configAnimation;