import $ from "jquery";
import gsap from "gsap";
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([Navigation, Pagination, Autoplay]);

export default function Slider() {
    Badge();
    Gallery();
    Foodmenu();
}

function Foodmenu() {
    try {
        const swiper = new Swiper(".sliderfoodmenu", {
            speed: 800,
            observer: true,
            observeParents: true,
            grabCursor: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: "#foodmenu .slider-arrow-next",
                prevEl: "#foodmenu .slider-arrow-prev",
            },
            slidesPerView: 1,
            spaceBetween: 20,
            pagination: {
                el: "#foodmenu .swiper-pagination",
                dynamicBullets: true,
            },
        });

        const tl_modal = gsap.timeline({
            paused: true
        });

        tl_modal.set("#foodmenu", {
            display: "block",
        });

        tl_modal.to("#foodmenu", {
            display: "block",
            opacity: 1,
            duration: 1,
            ease: "power4.inOut"
        });

        tl_modal.fromTo(".foodmenu-wrap", {
            clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)"
        }, {
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
            duration: 1,
            ease: "expo.inOut"
        }, "-=0.5");


        tl_modal.to(".foodmenu-frame", {
            opacity: 1,
            duration: 0.5,
            ease: "power4.inOut"
        }, "-=0.5");

        $(".foodmenu-close").on("click", (e) => {
            tl_modal.reverse();

            gsap.set("#foodmenu .swiper-slide", {
                delay: 0.5,
                stagger: 0,
                display: "none"
            });
        });

        Open();

        function Open() {
            function clicked(e) {
                e.preventDefault();
                tl_modal.play();
            }

            $(".food-item-more-signature").on("click", (e) => {
                clicked(e);

                gsap.set("#foodmenu .foodmenu-signature", {
                    display: "block"
                });
            });

            $(".food-item-more-bowls").on("click", (e) => {
                clicked(e);

                gsap.set("#foodmenu .foodmenu-bowls", {
                    display: "block"
                });
            });

            $(".food-item-more-burritos").on("click", (e) => {
                clicked(e);

                gsap.set("#foodmenu .foodmenu-burritos", {
                    display: "block"
                });
            });

            $(".food-item-more-special").on("click", (e) => {
                clicked(e);

                gsap.set("#foodmenu .foodmenu-special", {
                    display: "block"
                });
            });

            $(".food-item-more-sides").on("click", (e) => {
                clicked(e);

                gsap.set("#foodmenu .foodmenu-sides", {
                    display: "block"
                });
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function Badge() {
    try {
        const swiper = new Swiper(".sliderbadge", {
            speed: 800,
            grabCursor: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: "#badge .slider-arrow-next",
                prevEl: "#badge .slider-arrow-prev",
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                }
            }
        });
    } catch (error) {}
}


function Gallery() {
    try {
        const swiper = new Swiper(".slidergallery", {
            grabCursor: true,
            speed: 800,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: "#season .slider-arrow-next",
                prevEl: "#season .slider-arrow-prev",
            },
            slidesPerView: 1,
        });
    } catch (error) {}
}