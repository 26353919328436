import $ from "jquery";
import gsap from "gsap";

export default function Iframe() {

    const tl_iframe = gsap.timeline({
        paused: true
    });

    tl_iframe.set("#iframe", {
        display: "block",
    });

    tl_iframe.to("#iframe", {
        display: "block",
        opacity: 1,
        duration: 1,
        ease: "power4.inOut"
    });

    tl_iframe.fromTo(".iframe-wrap", {
        clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)"
    }, {
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
        duration: 1,
        ease: "expo.inOut"
    }, "-=0.5");

    tl_iframe.to(".iframe-frame", {
        opacity: 1,
        duration: 0.5,
        ease: "power4.inOut"
    }, "-=0.5");

    $(".iframe-close").on("click", (e) => {
        tl_iframe.reverse();

        setTimeout(() => {
            $('.iframe-frame').empty();
        }, 600);
    });

    Book();
    Schedule();

    function Book() {
        $(".iframe-book").on("click", (e) => {
            e.preventDefault();
            tl_iframe.play();
            const iframecontent = "<iframe src='https://docs.google.com/forms/d/1yxGKrpl4ap3Fd-QcT2Rz4NTH1jJEYDZIX7j4TJPM8eY/viewform?embedded=true' width='641' height='1251' frameborder='0' marginheight='0' marginwidth='0'>Loading...</iframe>"
            $('.iframe-frame').html(iframecontent);
        });
    }

    function Schedule() {
        $(".iframe-schedule").on("click", (e) => {
            e.preventDefault();
            tl_iframe.play();

            const iframecontent = "<iframe src='https://calendar.google.com/calendar/b/4/embed?showTitle=0&amp;showNav=0&amp;showDate=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;mode=WEEK&amp;height=200&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=j2p7bneddsnrcb46cbsirpl79c%40group.calendar.google.com&amp;color=%23182C57&amp;ctz=America%2FLos_Angeles' style='border-width:0' width='1160' height='200' frameborder='0' scrolling='no'></iframe>"
            $('.iframe-frame').html(iframecontent);
        });
    }
}