import gsap from "gsap";
import { anim_fadeBottom } from "./config/animation";
import { locoScroll } from './locomotive';


export default function Header() {

    try {
        scrollTo();
    } catch (error) {}

    try {
        navbarmenu();
    } catch (error) {}
}

function scrollTo() {
    gsap.utils.toArray(".scrollto-contact").forEach((el, i) => {
        el.addEventListener("click", (e) => {
            e.preventDefault();
            locoScroll.scrollTo("#contact", {
                offset: -100
            });
        });
    });
}

function navbarmenu() {
    const elMenu = document.querySelector(".navbar-menu");
    let ismenuopen = false;

    const tl_menuopen = gsap.timeline({
        paused: true,
        defaults: {
            duration: 0.8,
            ease: "expo.inOut"
        }
    });

    tl_menuopen.to("#navbar-mobile", {
        scaleX: 1,
        display: "block"
    });

    tl_menuopen.from(".navbar-mobile-list-item", anim_fadeBottom, "-=0.2");

    tl_menuopen.to(".navbar-menu-line-1", {
        rotation: 40
    }, 0);

    tl_menuopen.to(".navbar-menu-line-2", {
        y: -9,
        rotation: -40,
    }, 0);

    function menuopen() {
        if (ismenuopen === false) {
            tl_menuopen.play();

            ismenuopen = true;
        } else {
            tl_menuopen.reverse();

            ismenuopen = false;
        }

    }

    try {
        gsap.utils.toArray(".navbar-mobile-list-item").forEach((el, i) => {
            el.addEventListener("click", (e) => {
                tl_menuopen.reverse();
                ismenuopen = false;
            });
        });
    } catch (error) {}


    elMenu.addEventListener("click", menuopen);
}