import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function Food() {
    try {
        gsap.utils.toArray(".rotate-scroll").forEach((el, i) => {
            // On scroll trigger
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: "#food",
                    start: "top top",
                    scrub: true
                },
            });
    
            tl_universal.to(
                el, {
                    rotation: 360,
                    ease: "none",
                });
        });
    } catch (error) {}
}
