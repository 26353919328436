import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function Intro() {
    const tl_intro = gsap.timeline({
        onComplete: ()=> {
            // gsap.to(".intro-img-main, .intro-img-second", {
            //     rotation: 360,
            //     ease: "none",
            //     scrollTrigger: {
            //         trigger: "#intro",
            //         start: "top top",
            //         scrub: true
            //     }
            // });
        }
    });

    tl_intro.from(".intro-img-main", {
        scale: 2,
        rotation: 360,
        duration: 2,
    });
}